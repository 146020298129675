rodijco.controller 'ProductsFormController', [
  '$scope'
  '$routeParams'
  '$location'
  'httpService'
  (scope, routeParams, location, httpService) ->

    scope.formData = {}
    scope.group = routeParams.group
    scope.id = routeParams.id
    scope.edit = routeParams.id != 'new' ? true : false

    scope.werklastOptions = ['1.05', '1.1', '1.25', '1.5', '2.0', 'K1', 'K2', 'K3']

    if scope.group
      httpService.get "products/groups/#{scope.group}", (response) ->
        scope.groupData = response.data

    if scope.edit
      httpService.get "products/#{scope.id}", (response) ->
        scope.formData = response.data

    scope.save = (formData, forceNew = false) ->
      formData._id = scope.id
      formData.product_group = scope.group

      if forceNew
        formData._id = 'new'
        routeParams.id = 'new'

      httpService.post "products/#{routeParams.id}", formData, (response) ->
        if response.success
          scope.formData = {}
          Materialize.toast 'Product opgeslagen', DIALOG_DURATION
          location.path("/products/#{scope.group}")
        else
          scope.message = response.data?.message

]
